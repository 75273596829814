import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { selectAuthDetails, selectSharedDetails } from "../../../redux/selectors";
import { RootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import API from "../../../service/api.service";
import { AppDispatch } from "../../../redux/store";
import { showToaster } from "../../../redux/reducers/shared.reducer";
import { AlternativeEmail, AlternativeMobile, ErrorMessage, ToasterType, UpdateCustomerProfile } from "../../../types";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { base64ToBlob, fetchAddress, getCroppedImg, getGoogleAPIKey, validateEmail, validateMobileNumber } from "../../../service/common";
import moment from "moment";
import { ShimmerLoader } from "../../common";
import { alternativeEmailList, alternativeMobileList } from "../../../redux/actions";
import ConfirmationModal from "../../ModalComponent/Confirm.component";
import { updateCustomerProfile } from "../../../redux/reducers/auth.reducer";
import './my-profile.scss';
import 'react-datepicker/dist/react-datepicker.css';
import Cropper, { Area } from "react-easy-crop";

// Define the Yup validation schema
const validationSchema = Yup.object().shape({
  patient_image: Yup.string().optional(),
  patient_first_name: Yup.string().required('First name is required'),
  patient_last_name: Yup.string().required('Last name is required'),
  dob_month: Yup.string()
    .matches(/^\d{1,2}$/, 'Month must be exactly 2 digits')
    .test('is-valid-month', 'Month must be between 01 and 12', (value) => {
      if (!value) return false;
      const month = parseInt(value, 10);
      return month >= 1 && month <= 12;
    })
    .required('Month is required'),
  dob_day: Yup.string()
    .matches(/^\d{1,2}$/, 'Day must be exactly 2 digits')
    .test('is-valid-day', 'Day must be between 01 and 31', (value) => {
      if (!value) return false;
      const day = parseInt(value, 10);
      return day >= 1 && day <= 31;
    })
    .required('Day is required'),
  dob_year: Yup.string()
    .matches(/^\d{4}$/, 'Year must be exactly 4 digits')
    .required('Year is required'),
  patient_address: Yup.string().required('Address is required'),
  zip: Yup.string().required('ZIP is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
});

interface AddAlternativeEmailMobile {
  addEmail: boolean,
  email?: string;
  addMobile: boolean,
  mobile?: string;
}

const defailtEmailMobile: AddAlternativeEmailMobile = {
  addEmail: false,
  email: '',
  addMobile: false,
  mobile: ''
}

type DeleteArgs = {
  id: number, 
  type: 'Email' | 'Mobile';
}

export const MyProfile = () => {
  const [loading, setLoading] = useState(false);
  const [showConfirmDel, setShowConfirmDel] = useState(false);
  const [deleteArgs, setDeleteArgs] = useState<DeleteArgs | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [isCropping, setIsCropping] = useState(false);
  // const [dateValue, setDateValue] = useState<Date | null>(null);
  
  const [addAlternativeEmailMobile, setAddAlternativeEmailMobile] = useState<AddAlternativeEmailMobile>(defailtEmailMobile);
  const { user, patient_id } = useSelector((state: RootState) => selectAuthDetails(state));
  const { alternative_emails, alternative_mobiles } = useSelector((state: RootState) => selectSharedDetails(state));

  // const [location, setLocationData] = useState<Location>();

  const [previewImage, setPreviewImage] = useState<any>((user?.patient_image || "../../assets/images/place-holder.png"));
  const [uploadThisImage, setUploadThisImage] = useState<any>();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if(!alternative_emails?.length){
      dispatch(alternativeEmailList());
    }
    if(!alternative_mobiles?.length){
      dispatch(alternativeMobileList());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const { setValue, register, handleSubmit, formState: { errors } } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      patient_first_name: user?.patient_first_name || "",
      patient_last_name: user?.patient_last_name || "",
      dob_month: user?.date_of_birth ? moment(user?.date_of_birth).format('MM'): ' ',
      dob_year: user?.date_of_birth ? moment(user?.date_of_birth).format('YYYY'): ' ',
      dob_day: user?.date_of_birth ? moment(user?.date_of_birth).format('DD') : ' ',
      patient_address: user?.patient_address || "",
      zip: user?.zip || "",
      city: user?.city || "",
      state: user?.state || "",
      patient_image: user?.patient_image || "",
    }
  });

  const onSubmit = async (data: any) => {
    if(!patient_id){
      return ;
    }

    const payload: UpdateCustomerProfile = {
      patient_id, 
      patient_first_name: data.patient_first_name, 
      patient_last_name: data.patient_last_name, 
      date_of_birth: `${data.dob_year}-${data.dob_month.padStart(2, '0')}-${data.dob_day.padStart(2, '0')}`,
      patient_address: data.patient_address, 
      zip: data.zip, 
      city: data.city, 
      city_id: data.city_id,
      state : data.state,
      patient_image: data.patient_image
    }

    setLoading(true);
    const response = await API({
      api: `profile/edit_profile`,
      body: payload,
      type: 'POST'
    });
    setLoading(false);

    if (!response || (response.is_error && response.is_error === 1)) {
      dispatch(showToaster({...response, message: (response?.original_override_text || response?.original_text || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true}))
    }
    else{
      dispatch(updateCustomerProfile(payload));
      dispatch(showToaster({ message: "Profile updated succesfully!", type: ToasterType.Success, status: true}))
    }
  };

  useEffect(() => {
    if(uploadThisImage){
      // uploadImage();
      setIsCropping(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadThisImage])
  

  const uploadImage = async (croppedImage: string) => {
    setLoading(true);

    if(croppedImage){
      // const profile_image = await base64ToBlob(uploadThisImage);
      const profile_image = await base64ToBlob(croppedImage);

      const response = await API({
        api: `profile/edit_profile_image`,
        body: { profile_image, patient_id },
        type: 'FORM'
      });
  
      if (!response || (response.is_error && response.is_error === 1)) {
        dispatch(showToaster({...response, message: (response?.original_override_text || response?.override_text || response?.original_text || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true}))
      }
      else{
        setValue('patient_image', response.patient_image || '', { shouldValidate: false });
        setPreviewImage(response.patient_image);
        dispatch(updateCustomerProfile(response));
        dispatch(showToaster({ message: "Uploaded, now save your profile to keep changes!", type: ToasterType.Info, status: true}))
      }
    }

    setLoading(false);
  }

  // Function to handle the image preview
  const handleImageChange = async (e: any) => {

    const file = e?.target?.files && e?.target.files.length ? e?.target.files[0] : null;

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadThisImage(reader.result);
      };
      reader.readAsDataURL(file);
    } 
    else{
      dispatch(showToaster({ message: "Please choose a valid image!", type: ToasterType.Danger, status: true}))
    }
  };

  const getLocation = async (location_data: any) => {
    const loc_data = fetchAddress(location_data);
    // console.group(location_data, loc_data)
    
    // const newCity = fetchAddress(location_data, "locality"); // City
    // const newState = fetchAddress(location_data, "administrative_area_level_1"); // State
    // const newZip = fetchAddress(location_data, "postal_code"); // ZIP

    if(loc_data?.state_name){
      setValue('state', loc_data.state_name, { shouldValidate: true });
    }
    else{
      setValue('state', '', { shouldValidate: true });
    }
    setValue('zip', (loc_data?.zip || loc_data?.zipCode || ''), { shouldValidate: true });
    setValue('city', (loc_data?.city || ''), { shouldValidate: true });
    setValue('patient_address', (location_data?.formatted_address || ''), { shouldValidate: true });
  }

  const handleEmailMobileSave = async () => {
    setLoading(true);
    // console.log(addAlternativeEmailMobile);
    if(addAlternativeEmailMobile.addEmail && addAlternativeEmailMobile?.email){
      if(validateEmail(addAlternativeEmailMobile.email)){
        const response = await API({
          api: `profile/add_alternate_email`,
          body: { patient_id, alternate_email: addAlternativeEmailMobile.email },
          type: 'POST'
        });
    
        if (!response || (response.is_error && response.is_error === 1)) {
          dispatch(showToaster({ message: (response?.original_override_text || response?.override_text || response?.original_text || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true}))
        }
        else{
          setAddAlternativeEmailMobile(defailtEmailMobile);
          dispatch(alternativeEmailList());
          dispatch(showToaster({ message: "Alternative email added successfully!", type: ToasterType.Success, status: true}))
        }
      }else{
        dispatch(showToaster({ message: "Please enter valid email!", type: ToasterType.Danger, status: true}))
      }
      
    }
    else if(addAlternativeEmailMobile.addMobile){

      if(validateMobileNumber(addAlternativeEmailMobile.mobile)){
        const response = await API({
          api: `profile/add_alternate_mobile`,
          body: { patient_id, alternate_mobile_country_code: user?.patient_mobile_country_code , alternate_mobile: addAlternativeEmailMobile.mobile },
          type: 'POST'
        });
    
        if (!response || (response.is_error && response.is_error === 1)) {
          dispatch(showToaster({ message: (response?.original_override_text || response?.override_text || response?.original_text || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true}))
        }
        else{
          setAddAlternativeEmailMobile(defailtEmailMobile);
          dispatch(alternativeMobileList());
          dispatch(showToaster({ message: "Alternative mobile added successfully!", type: ToasterType.Success, status: true}))
        }
      }
      else{
        dispatch(showToaster({ message: "Please enter valid mobile number - maximum 10 digit! ", type: ToasterType.Danger, status: true}))
      }      
    }
    setAddAlternativeEmailMobile(defailtEmailMobile);
    setLoading(false);
  }

  const deleteAlternativeEmail = async (confirmed: boolean) => {
    if(!confirmed){ return };
    const type = deleteArgs?.type;

    setLoading(true);

    const params = (type === 'Email') ?  { patient_id, pae_id: deleteArgs?.id } :  { patient_id, pam_id: deleteArgs?.id };
    const response = await API({
      api: (type === 'Email') ? `profile/delete_alternate_email` : `profile/delete_alternate_mobile`,
      body: params,
      type: 'POST'
    });

    if (!response || (response.is_error && response.is_error === 1)) {
      dispatch(showToaster({ message: (response?.original_override_text || response?.override_text || response?.original_text || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true}))
    }
    else{
      dispatch(showToaster({ message: `Alternative ${type} deleted successfully!`, type: ToasterType.Success, status: true}))
    
      if(type === 'Email'){
        dispatch(alternativeEmailList());
      }
      else{
        dispatch(alternativeMobileList());
      }
    }

    setLoading(false);

  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === 'Enter') { e.preventDefault(); } };

  const handleCropComplete = async () => {
    if (uploadThisImage && croppedArea) {
      const croppedImage = await getCroppedImg(uploadThisImage, croppedArea);
      console.log(croppedImage);
      setUploadThisImage(null); // Reset after cropping
      setPreviewImage(croppedImage); // Set the cropped image as preview
      setIsCropping(false); // Disable cropping
      // setUploadThisImage(croppedImage); // Reset after cropping
      uploadImage(croppedImage);
    }
  };

  const fileInputRef: MutableRefObject<HTMLInputElement | null> = useRef(null);
  const cropperRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

  useEffect(() => {

    const handleClickOutside = (event: MouseEvent) => {
      if (cropperRef.current && !cropperRef.current.contains(event.target as Node)) {
        setIsCropping(false);
      }
    };

    const handleEscPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsCropping(false);
        setUploadThisImage(null);
        if (fileInputRef.current) { 
          fileInputRef.current.value = ''; 
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscPress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscPress);
    };
  }, [cropperRef]);

  const CancelCrop = () => {
    setIsCropping(false);
    setUploadThisImage(null);
    if (fileInputRef.current) { 
      fileInputRef.current.value = ''; 
    }
}

  return (
    <>
      {loading && <ShimmerLoader />}

      {/* Cropper */}
      {isCropping && (
        <div
          ref={cropperRef}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%", /* Increased the width */
            height: "100%", /* Increased the height */
            background: "none", /* Remove the background */
            zIndex: 1000,
            padding: "0", /* Remove padding */
            boxShadow: "none", /* Remove box shadow */
            overflow: "hidden"
          }          
          }
        >
          <Cropper
            image={uploadThisImage!}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) =>
              setCroppedArea(croppedAreaPixels)
            }
            onZoomChange={setZoom}
          />

          {/* Buttons for actions */}
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 10,
              display: "flex",
              gap: "10px",
            }}
          >
            <button
              className="btn btn-secondary"
              onClick={handleCropComplete}
              style={{ zIndex: 11 }}
            >
              Upload
            </button>
            <button className="btn btn-secondary"  onClick={CancelCrop} style={{ zIndex: 11, background: "#FFF", border: "none" }}>
              Cancel
            </button>
          </div>
        </div>
      )}
    

      {showConfirmDel && (
        <ConfirmationModal
          message="Are you sure you want to delete?"
          show={showConfirmDel}
          onHide={() => setShowConfirmDel(false)}
          onConfirm={(confimed) => deleteAlternativeEmail(confimed)}
        />
      )}
      <div className="px-md-5">
        <h4 className="fs-20 fw-600 m-0 text-grey mb-1">My Profile</h4>
        <p className="fs-16 fw-400 m-0 mb-4 text-muted">
          Update your photo and personal details here
        </p>

        <div className="d-flex align-items-center mb-3">
          <img
            src={
              previewImage?.includes("placeholder")
                ? "../../assets/images/place-holder.png"
                : previewImage || "../../assets/images/place-holder.png"
            }
            alt="Avatar"
            className="rounded-circle hw-80 me-3 border"
            onClick={() =>
              document.getElementById("patient-image-input")?.click()
            } // Trigger file input click
            style={{ cursor: "pointer" }}
          />
          <h4 className="fs-20 fw-600 m-0">
            {user?.patient_first_name} {user?.patient_last_name}
          </h4>
          <Form.Group className="ml-3 d-none">
            <Form.Control
              type="file"
              accept=".png, .jpg, .jpeg, .gif"              
              id="patient-image-input"
              src={previewImage}
              {...register("patient_image")}
              onChange={(e) => {
                handleImageChange(e);
              }}
              isInvalid={!!errors.patient_image}
              ref={fileInputRef}
            />
          </Form.Group>
        </div>

        <Row className="pb-4 my-4 border-bottom">
          <Col md={6}>
            <p className="fs-14 fw-400 text-muted m-0 mb-1">Email:</p>
            <h4 className="fs-16 fw-600 m-0 text-grey">
              {user?.patient_email}
            </h4>
          </Col>
          <Col md={3}>
            <p className="fs-14 fw-400 text-muted m-0 mb-1">Phone Number:</p>
            <h4 className="fs-16 fw-600 m-0 text-grey">
              {user?.patient_mobile_country_code}-{user?.patient_mobile}
            </h4>
          </Col>
          <Col md={3}>
            <p className="fs-14 fw-400 text-muted m-0 mb-1">SSN Number:</p>
            <h4 className="fs-16 fw-600 m-0 text-grey">
              *****{user &&  user.patient_ssn && user.patient_ssn.slice(-4)}
            </h4>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6} className="mb-2">
              <label className="fs-14 fw-500 text-muted">First Name</label>          
              <input
                type="text"
                {...register("patient_first_name", {
                  required: "Patient First Name is required",
                })}
                className={`input_field w-100 ${
                  errors.patient_first_name ? "is-invalid" : ""
                }`}
                placeholder="Enter First Name"
              />
              {errors.patient_first_name && (
                <p className="m-0 fs-12 text-danger">
                  {(errors.patient_first_name.message as string) || ""}
                </p>
              )}
            </Col>

            <Col md={6} className="mb-2">
              <label className="fs-14 fw-500 text-muted">Last Name</label>
              <input
                type="text"
                {...register("patient_last_name", {
                  required: "Patient Last Name is required",
                })}
                className={`input_field w-100 ${
                  errors.patient_last_name ? "is-invalid" : ""
                }`}
                placeholder="Enter Last Name"
              />
              {errors.patient_last_name && (
                <p className="m-0 fs-12 text-danger">
                  {(errors?.patient_last_name?.message as string) || ""}
                </p>
              )}
            </Col>

            <Col md={6} className="mb-2">
              <label className="fs-14 fw-500 text-muted w-100">
                Date of Birth
              </label>
              {/* <input type="text" {...register('date_of_birth')} className="input_field w-100" placeholder="Enter DOB" /> */}
              <Form.Group className="d-flex align-items-center input_field p-0">
                
                <input
                  type="text"
                  {...register("dob_month", {
                    required: "Month is required",
                  })}
                  className={`input_field hw-60 h-100 border-0 m-0 ${
                    errors.dob_month ? "is-invalid" : ""
                  }`}
                  placeholder="MM"
                  maxLength={2}
                />
                /
                <input
                  type="text"
                  {...register("dob_day", {
                    required: "Day is required",
                  })}
                  className={`input_field hw-60 h-100 border-0 m-0 ${
                    errors.dob_day ? "is-invalid" : ""
                  }`}
                  maxLength={2}
                  placeholder="DD"
                />
                /
                <input
                  type="text"
                  {...register("dob_year", {
                    required: "Year is required",
                  })}
                  className={`input_field flex-fill border-0 m-0 ${
                    errors.dob_year ? "is-invalid" : ""
                  }`}
                  placeholder="YYYY"
                  maxLength={4}
                />              
              </Form.Group> 
              {/* <p>
                {errors ? (
                  <ul>
                    {errors?.dob_month?.message ? (<li>{errors.dob_month.message as string}</li>) : ''}
                    {errors?.dob_day?.message ? (<li>{errors.dob_day.message as string}</li>) : ''}
                    {errors?.dob_year?.message ? (<li>{errors.dob_year.message as string}</li>) : ''}
                  </ul>
                ) : (
                  ''
                )}
              </p> */}

              {errors.dob_day && (
                <p className="m-0 fs-12 text-danger">
                  {(errors?.dob_day?.message as string) || ""}
                </p>
              )}
              {errors.dob_month && (
                <p className="m-0 fs-12 text-danger">
                  {(errors?.dob_month?.message as string) || ""}
                </p>
              )}
              {errors.dob_year && (
                <p className="m-0 fs-12 text-danger">
                  {(errors?.dob_year?.message as string) || ""}
                </p>
              )}
            
            </Col>

            <Col md={6} className="mb-2">
              <label className="fs-14 fw-500 text-muted w-100">Address</label>
              <ReactGoogleAutocomplete
                apiKey={getGoogleAPIKey()}
                onPlaceSelected={(place) => getLocation(place)}
                className="input_field w-100"
                defaultValue={user?.patient_address}
                options={{ 
                  componentRestrictions: { country: user?.country_code } 
                }}
              />
            </Col>

            <Col md={6} className="mb-3">
              <label className="fs-14 fw-500 text-muted">Zip Code</label>
              <input
                type="text"
                {...register("zip", {
                  required: "Zip is required",
                })}
                className={`input_field w-100 ${
                  errors.zip ? "is-invalid" : ""
                }`}
                placeholder="Enter Zip Code"
              />

              {errors.zip && (
                <p className="m-0 fs-12 text-danger">
                  {(errors.zip.message as string) || ""}
                </p>
              )}
            </Col>

            <Col md={6} className="mb-2">
              <label className="fs-14 fw-500 text-muted">City</label>              
              <input
                type="text"
                {...register("city", {
                  required: "City is required",
                })}
                className={`input_field w-100 ${
                  errors.city ? "is-invalid" : ""
                }`}
                placeholder="Enter City"
              />
              {errors.city && (
                <p className="m-0 fs-12 text-danger">
                  {(errors.city.message as string) || ""}
                </p>
              )}
            </Col>

            <Col md={6} className="mb-2">
              <label className="fs-14 fw-500 text-muted">State</label>              
              <input
                type="text"
                {...register("state", {
                  required: "state is required",
                })}
                className={`input_field w-100 ${
                  errors.state ? "is-invalid" : ""
                }`}
                placeholder="Enter State"
              />
              {errors.state && (
                <div className="invalid-feedback">
                  {(errors.state.message as string) || ""}
                </div>
              )}
            </Col>
            <Col md={8} className="mb-2">
              {alternative_emails?.length < 2 && (
                <label
                  className="fs-14 fw-600 text-primary cursor my-3 me-3"
                  onClick={() =>
                    setAddAlternativeEmailMobile({
                      ...defailtEmailMobile,
                      addEmail: true,
                    })
                  }
                >
                  + Add alternative email
                </label>
              )}
              {alternative_mobiles?.length < 2 && (
                <label
                  className="fs-14 fw-600 text-primary cursor"
                  onClick={() =>
                    setAddAlternativeEmailMobile({
                      ...defailtEmailMobile,
                      addMobile: true,
                    })
                  }
                >
                  + Add alternative mobile
                </label>
              )}
              {(addAlternativeEmailMobile.addEmail ||
                addAlternativeEmailMobile.addMobile) && (
                <InputGroup className="mb-2">
                  <div className="d-flex align-items-center ps-0 input_field w-100  overflow-hidden">
                    {!addAlternativeEmailMobile.addEmail ? (
                      <span className="hw-40 h-100 d-flex align-items-center justify-content-center border-end">
                        {addAlternativeEmailMobile.addMobile
                          ? user?.patient_mobile_country_code
                          : ""}
                      </span>
                    ) : null}
                    <Form.Control
                      type="text"
                      className="input_field flex-fill border-0 m-0 py-0"
                      placeholder={`Enter ${
                        addAlternativeEmailMobile.addEmail ? "Email" : "Mobile"
                      }`}
                      onChange={(e) =>
                        setAddAlternativeEmailMobile({
                          ...addAlternativeEmailMobile,
                          email: addAlternativeEmailMobile.addEmail
                            ? e.target.value
                            : "",
                          mobile: addAlternativeEmailMobile.addMobile
                            ? e.target.value
                            : "",
                        })
                      }
                      maxLength={addAlternativeEmailMobile.addMobile ? 10 : undefined}
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                  <Button
                    variant="secondary"
                    className="rounded px-3"
                    onClick={handleEmailMobileSave}
                  >
                    + Add
                  </Button>
                </InputGroup>
              )}
              {alternative_emails?.length > 0 && (
                <>
                  {alternative_emails.map((email: AlternativeEmail, index) => (
                    <div key={index}>
                      <label className="fs-14 fw-600 text-secondary">
                        Alternate email {index + 1}
                      </label>
                      <span className="d-flex input_field align-items-center overflow-hidden p-0">
                        <input
                          type="text"
                          className="input_field flex-fill border-0 m-0 border-end rounded-0"
                          placeholder="Enter alternate email"
                          defaultValue={email.alternate_email}
                        />
                        <span
                          className={`hw-80 h-100 fs-14 fw-600 d-flex align-items-center justify-content-center cursor text-danger`}
                          onClick={() => {
                            setDeleteArgs({ id: email?.pae_id, type: "Email" });
                            setShowConfirmDel(true);
                          }}
                        >
                          Delete
                        </span>
                      </span>
                    </div>
                  ))}
                </>
              )}
              {alternative_mobiles?.length > 0 && (
                <>
                  {/* <AlertHeading>Alternative Mobiles</AlertHeading> */}
                  {alternative_mobiles.map(
                    (mobile: AlternativeMobile, index) => (
                      <div key={index}>
                        <label className="fs-14 fw-600 text-secondary">
                          Alternate Number {index + 1}
                        </label>
                        <span className="d-flex input_field align-items-center overflow-hidden p-0">
                          <span className="hw-40 h-100 d-flex align-items-center justify-content-center border-end">
                            {user?.patient_mobile_country_code || ""}
                          </span>
                          <input
                            type="text"
                            className="input_field flex-fill border-0 m-0 border-end rounded-0"
                            placeholder="Enter alternate email"
                            defaultValue={mobile.alternate_mobile}
                          />
                          <span
                            className={`hw-80 h-100 fs-14 fw-600 d-flex align-items-center justify-content-center cursor text-danger`}
                            onClick={() => {
                              setDeleteArgs({
                                id: mobile?.pam_id,
                                type: "Mobile",
                              });
                              setShowConfirmDel(true);
                            }}
                          >
                            Delete
                          </span>
                        </span>
                      </div>
                    )
                  )}
                </>
              )}
            </Col>
          </Row>

          <Button variant="secondary" type="submit" className="mnw-125 w-10">
            Save
          </Button>
        </Form>
      </div>
    </>
  );
};
