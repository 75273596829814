import { SaveFinancingErrorParams } from "../types";
import { ContractDetail } from "../types/contract.type";
import API from "./api.service";
import { md5 } from "./md5.service";

export const isEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const crypto = (data: string) => {
    return md5(data);
}

export const saveFinancingError = async (data: SaveFinancingErrorParams) => {
    const tracking_data = {
      contract_id: '',
      split_req_id: '',
      error_message: data.error_message || '',
      error_type: data.error_type || '',
      payment_intent_id: '',
      contract_token: data.contract_token || '',
      patient_id: data.patient_id || '',
      pc_code: data.pc_code || ''
    };

    await API({type: 'POST', api: 'proposal/save_financing_errors', body: tracking_data});
    return true;
}

export const getGoogleAPIKey = () => {
    if (window.location.href.includes('.recuvery.com')) {
        return 'AIzaSyDuayE9g_ErYJHUDNwcELxOoaiidjG4E2k';
    } else {
        return 'AIzaSyAudUxsyfqNreXJdNllGSnMiyWZqdZHF-I'
    }
}

// export const fetchAddress = ( data: any, type: string ) => {
//     const component = data.address_components.find((comp: any) => comp.types.includes(type));
//     return component ? component.long_name : null;
// };

export async function fileToBase64(file: File): Promise<ArrayBuffer> {
    const reader = new FileReader();
  
    // Use async/await to handle FileReader events
    const base64String = await new Promise<ArrayBuffer>((resolve, reject) => {
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as ArrayBuffer);
        } else {
          reject(new Error("File reading failed"));
        }
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file); // Converts file to base64
    });
  
    return base64String;
  }

export async function base64ToBlob(base64String: string) {
  const dataURI = base64String;
  // const byteString = atob(dataURI.split(',')[1]);
  
  if (dataURI.includes(',')) { 
    const parts = dataURI.split(','); if (parts[1]) { 
    const byteString: string = atob(parts[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], {
      type: 'image/jpeg'
    });
    return blob;  

  } 
  else { 
    console.error('Invalid dataURI format: base64 string is missing.'); } } else { console.error('Invalid dataURI format: comma is missing.'); 
    return null;
  }
}

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const validateMobileNumber = (mobileNumber: any): boolean => { 
  const mobileRegex = /^[0-9]{10}$/; // Ensure exactly 10 digits 
  return mobileRegex.test(mobileNumber); 
}

export const getCalculatedTransactionFee = (contract: ContractDetail, comparable_value: number) => {
  // comparable_value = comparable_value;
  // contract['recurring_extra_amount'] = contract.recurring_extra_amount || 0;
  // contract.recurring_extra_percent = contract.recurring_extra_percent;
  // contract.threshold_amount = contract.threshold_amount;
  // contract['threshold_recurring_extra_amount'] = contract.threshold_recurring_extra_amount || 0;
  // contract['threshold_recurring_extra_percent'] = contract.threshold_recurring_extra_percent || 0;
  // comparable_value = comparable_value);
  let transaction_fee = 0;
  if (comparable_value === 0) {
    return 0;
  } else if ((comparable_value < contract.threshold_amount) || (contract.threshold_amount === undefined && contract.recurring_extra_amount !== undefined)) {
    if (contract.recurring_extra_percent) {
      transaction_fee += (comparable_value * contract.recurring_extra_percent) / 100;
    }
    if (contract.recurring_extra_amount) {
      transaction_fee += contract.recurring_extra_amount;
    }
  } else {
    if (contract.threshold_recurring_extra_percent) {
      transaction_fee += (comparable_value * contract.threshold_recurring_extra_percent) / 100;
    }
    if (contract.threshold_recurring_extra_amount) {
      transaction_fee += contract.threshold_recurring_extra_amount;
    }
  }
  return transaction_fee;
}

export const getAmountWithTransactionFee = (contract: ContractDetail, comparable_value: number): number => {

  // comparable_value = parseFloat(comparable_value);
  let transaction_fee = 0;
  if (comparable_value === 0) {
    return 0;
  } else if ((comparable_value < contract?.threshold_amount) || (contract?.threshold_amount === undefined && contract?.recurring_extra_amount !== undefined)) {
    if (contract.recurring_extra_percent) {
      transaction_fee += (comparable_value * contract?.recurring_extra_percent) / 100;
    }
    if (contract.recurring_extra_amount) {
      transaction_fee += contract?.recurring_extra_amount;
    }
  } else {
    if (contract?.threshold_recurring_extra_percent) {
      transaction_fee += (comparable_value * (contract?.threshold_recurring_extra_percent)) / 100;
    }
    if (contract?.threshold_recurring_extra_amount) {
      transaction_fee += contract?.threshold_recurring_extra_amount;
    }
  }
  return parseFloat((transaction_fee + comparable_value).toFixed(2));
}

export const extractMMddyyyy = (dateString: string) => {
  const parts = dateString.split('-');
  if (parts.length !== 3) {
    return ''; // Handle invalid date string format
  }

  const year = (parts[0]);
  const month = parts[1]?.padStart(2, '0');
  const day = parts[2]?.padStart(2, '0');

  return { mm: month, dd: day, yyyy: year };
}

export const fetchAddress = ( item: any ) => {
  const desire_object: any = {};
    let temp: any
    desire_object.place_id = item.place_id
    desire_object.item = item
    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_code') || inner.types.includes('plus_code'));
    temp = temp && temp.long_name;
    desire_object.zip = temp;
    desire_object.zipCode = temp;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('locality'));
    temp = temp || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_2'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('postal_town'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('neighborhood'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_3'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('sublocality'))) || (item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('sublocality_level_1')));

    temp = temp && temp.long_name;
    desire_object.city = temp;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('administrative_area_level_1'));
    desire_object.state_name = temp && temp.long_name;
    desire_object.state_code = temp && temp.short_name;
    desire_object.State = temp && temp.short_name;


    // temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('country'));
    // desire_object.country_name = temp && temp.long_name;
    // desire_object.country_code = temp && temp.short_name;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('route'))
    desire_object.address_line_1 = temp && temp.long_name;

    temp = item && item.address_components && item.address_components.find((inner: any) => inner.types.includes('street_number'));
    desire_object.address_line_1 += temp && temp.long_name;

    if (item.geometry && item.geometry.location) {
      desire_object.latitude = item.geometry.location.lat();
      desire_object.longitude = item.geometry.location.lng();
    }
    return desire_object
};


export const validateDOBFields = (data: any) => {
  const dobFields = ["dob_day", "dob_month", "dob_year"];

  // Check how many of the DOB fields have a value (non-null, non-empty)
  const presentFields = dobFields.filter((field) => data[field]);

  // If any of the fields have a value, ensure all have values
  if (presentFields.length > 0 && presentFields.length !== dobFields.length) {
    return {
      isValid: false,
      message: `Select all from Date of birth!`,
    };
  }

  // Validation passed
  return { isValid: true };
}


export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number }
): Promise<string> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Canvas context not available");
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return canvas.toDataURL("image/jpeg");
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });
export const getOtherPayments = () => {
  return [
      { pt_name: "Recurring Payment", payment_type: 2 },
      { pt_name: "Partial Payment", payment_type: 11 },
      { pt_name: "Social Healthcare", payment_type: 8 },
      { pt_name: "Upfront Payment", payment_type: 38 },
      { pt_name: "Down Payment", payment_type: 4 },
      { pt_name: "Change Payment Date", payment_type: 10 },
      { pt_name: "Late Fees", payment_type: 7 },
      { pt_name: "Other", payment_type: 0 }
  ];
}