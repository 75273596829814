export interface InitalState {
    status: StatusType;
    error?: string | null;
    flag?: number | null;
}
export interface ApiResponse{
    is_error: number;
    original_text: string;
    flag: number;
    error?: string;
}

export enum StatusType {
    Idle = 'idle',
    Loading = 'loading',
    Succeeded = 'succeeded',
    Failed = 'failed'
}

export enum ToasterType {
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info'
}

export enum HttpStatusCode {
    // Informational responses (100–199)
    Continue = 100,
    SwitchingProtocols = 101,
    Processing = 102,
    EarlyHints = 103,

    // Successful responses (200–299)
    OK = 200,
    Created = 201,
    Accepted = 202,
    NonAuthoritativeInformation = 203,
    NoContent = 204,
    ResetContent = 205,
    PartialContent = 206,
    MultiStatus = 207,
    AlreadyReported = 208,
    IMUsed = 226,

    // Redirection messages (300–399)
    MultipleChoices = 300,
    MovedPermanently = 301,
    Found = 302,
    SeeOther = 303,
    NotModified = 304,
    UseProxy = 305,
    SwitchProxy = 306,
    TemporaryRedirect = 307,
    PermanentRedirect = 308,

    // Client error responses (400–499)
    BadRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    ProxyAuthenticationRequired = 407,
    RequestTimeout = 408,
    Conflict = 409,
    Gone = 410,
    LengthRequired = 411,
    PreconditionFailed = 412,
    PayloadTooLarge = 413,
    URITooLong = 414,
    UnsupportedMediaType = 415,
    RangeNotSatisfiable = 416,
    ExpectationFailed = 417,
    ImATeapot = 418,
    MisdirectedRequest = 421,
    UnprocessableEntity = 422,
    Locked = 423,
    FailedDependency = 424,
    TooEarly = 425,
    UpgradeRequired = 426,
    PreconditionRequired = 428,
    TooManyRequests = 429,
    RequestHeaderFieldsTooLarge = 431,
    UnavailableForLegalReasons = 451,

    // Server error responses (500–599)
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    HTTPVersionNotSupported = 505,
    VariantAlsoNegotiates = 506,
    InsufficientStorage = 507,
    LoopDetected = 508,
    NotExtended = 510,
    NetworkAuthenticationRequired = 511
}

type ToasterMessage = {
    contract_code: string,
    message: string
}
export interface Toaster {
    status?: boolean;
    type?: ToasterType;
    message?: string | ToasterMessage;
    duration?: number;
}

export interface Pagination {
    limit: number;
    offset: number;
}

export interface RejectResponse {
    is_error?: number,
    flag?: number,
    tracking_id?: string,
    request_id?: number,
    message: string,
    navigate?:Function;
    error?: string;
}
  

export enum DashboardTabType {
    // OVERVIEW = 'overview',
    // OVERVIEW_DETAILS = 'overview_details',
    CONTRACT_DETAILS = 'contract_details',
    PAYMENT_METHODS = 'payment_methods',
    TRANSACTION_HISTORY = 'transaction_history'
}

export interface PaymentParams {
    payment_matrix_type: string,
    payment_source_type: number
    patient_id?: string,

}

export type SaveFinancingErrorParams = {
    contract_id?: string,
    split_req_id?: string,
    error_message?: string
    error_type?: string,
    payment_intent_id?: string,
    contract_token?: string,
    patient_id?: string,
    pc_code?: string;
}

export type Location = {
    city_id?: number,
    city: string,
    state: string,
    zip: number
}

export type UploadedDoc = {
    file_name: string;
    doc_id: number,
    doc_view_url: string
}

export enum RedirectFrom {
    PROFILE_PAGE = "Profile Page",
    NEED_HELP = "Need Help"
}

export type UploadPaylod = {
    document_file: Blob,
    contract_id: number,
    charge_id: string
}

export enum SuccessMessage {
    DATE_CHANGED = "Next payment date has been changed!",
    PAYMENT_COMPLETED = "Payment completed successfully"
}

export enum ErrorMessage {
    SOMETHING_WRONG = "Something went wrong!",
}
