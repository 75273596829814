import Modal from "react-bootstrap/Modal";
import { Form, Button, Container } from "react-bootstrap";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import { selectAuthDetails, selectSharedDetails } from "../../redux/selectors";
import * as yup from "yup";
import { showToaster } from "../../redux/reducers/shared.reducer";
import API from "../../service/api.service";
import { AlternativeMobile, ErrorMessage, Reason, ToasterType } from "../../types";
import { useEffect, useState } from "react";
import { alternativeMobileList, reasonsList } from "../../redux/actions";

interface SpeakWithExpertProps {
    isActive: boolean;
    // handleShow: (args: PaymentParams) => void,
    handleClose: (arg: boolean) => void
}

interface SpeakWithExpertForm {
    name: string,
    patient_mobile: string,
    reason_id?: string,
    message: string,
    // terms: boolean,
};

const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required").min(3, "Name must be at least 3 characters"),
    patient_mobile: yup
        .string()
        .required("Phone Number is required")
        .matches(/^\d{10}$/, "Phone Number must be 10 digits"),
    reason_id: yup.string().required("Reason is required"),
    message: yup.string().required("Message is required"),
    // terms: yup.boolean().oneOf([true], "You must agree to the Terms & Conditions"),
});

const SpeakWithExpert = ({ isActive, handleClose }: SpeakWithExpertProps) => {  
    const [csrReqTz, setCsrReqTz] = useState('');
    const [csrReqTzName, setCsrReqTzName] = useState('');  

    const { user } = useSelector((state: RootState) => selectAuthDetails(state));
    const { reasonList } = useSelector((state: RootState) => selectSharedDetails(state));
    const { alternative_mobiles } = useSelector((state: RootState) => selectSharedDetails(state));

  
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const userTimezoneOffset = new Date().getTimezoneOffset() * -1; 
        const userTimezoneHours = Math.floor(userTimezoneOffset / 60);
        const userTimezoneMinutes = userTimezoneOffset % 60;
        const userTimezoneSign = userTimezoneHours < 0 ? '-' : '+';
        const paddedMinutes = Math.abs(userTimezoneMinutes).toString().padStart(2, '0');
        setCsrReqTz(`${userTimezoneSign}${Math.abs(userTimezoneHours)}${paddedMinutes}`);
        const userTimezoneName = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        setCsrReqTzName(userTimezoneName);

        dispatch(reasonsList({ limit: 100, offset: 0 }));

        if(!alternative_mobiles?.length){
            dispatch(alternativeMobileList());
          }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []); 

    const patient_name = `${user?.patient_first_name} ${user?.patient_last_name}`;

    const { setValue, register, handleSubmit, formState: { errors, isValid } } = useForm<SpeakWithExpertForm>({
        resolver: yupResolver(validationSchema) as Resolver<SpeakWithExpertForm>, // Explicitly cast
        defaultValues: {
          name: patient_name,
          patient_mobile: user?.patient_mobile || '',
          reason_id: '',
          message: '',
          // terms: false,
        },
        mode: "onChange"
      });
      
    
      const onSubmit = async (data: any) => {
        const today = new Date();
        const call_req_date = today.toLocaleDateString('en-GB'); // Format: DD-MM-YYYY 
        const call_req_time = today.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }); 

        const payload = {
            patient_mobile: data.patient_mobile,
            message: data.message,
            call_req_date: call_req_date || '',
            call_req_time: call_req_time || '',
            csr_req_tz: csrReqTz || '',
            csr_req_tz_name: csrReqTzName || '',
            reason_id: data.reason_id,
            patient_mobile_country_code: user?.patient_mobile_country_code,
            user_type: "patient"
        }

        const response = await API({
          api: `payment/add_csr_call_request`,
          body: payload,
          type: 'POST'
        });

        if (!response || (response.is_error && response.is_error === 1)) {
          dispatch(showToaster({...response, message: (response?.original_override_text || response?.original_text || ErrorMessage.SOMETHING_WRONG), type: ToasterType.Danger, status: true}))
        }
        else{
          dispatch(showToaster({ message: "Your request send successfully!", type: ToasterType.Success, status: true}));
          handleClose(false);
        }
      };

      const handleSelectChange = (selectedValue: string | undefined, field_name: "patient_mobile" | "reason_id") => {
        //const selectedValue = e.target.value;

        if(!selectedValue){ return false;}

        setValue(field_name, selectedValue);
      };

    return (
      <Modal show={isActive} onHide={() => handleClose(false)}>
        <Modal.Header className="p-4 pb-0 border-0 d-flex justify-content-between align-items-start">
          <h4 className="fs-18 fw-600 m-0">Speak with an expert</h4>
          <img
            src=" ../../assets/images/cross-circle.png"
            className="hw-30 cursor"
            onClick={() => handleClose(false)}
            alt=""
          />
        </Modal.Header>
        <Modal.Body className="pt-0 px-4">
          <Container className="mt-3">
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* Name */}
              <Form.Group className="mb-3" controlId="name">
                <Form.Label className="fs-14 fw-400 text-muted m-0">
                  Name
                </Form.Label>
                <Form.Control
                  className="input_field"
                  type="text"
                  placeholder="Enter your name"
                  isInvalid={!!errors.name}
                  {...register("name")}
                  disabled
                //   defaultValue={patient_name}
                />
                <Form.Control.Feedback type="invalid">
                  {(errors.name?.message as string) || ""}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Phone Number */}
              <Form.Group className="mb-3" controlId="patient_mobile">
                <Form.Label className="fs-14 fw-400 text-muted m-0">
                  Phone Number
                </Form.Label>

                {alternative_mobiles?.length > 0 || user?.patient_mobile ? (
                  <Form.Select
                    className="input_field"
                    {...register("patient_mobile")}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      handleSelectChange(e.target.value, "patient_mobile")
                    }
                  >
                    <option value={user?.patient_mobile}>
                      {user?.patient_mobile}
                    </option>
                    {alternative_mobiles?.length &&
                      alternative_mobiles.map(
                        (alternative_mobile: AlternativeMobile) => (
                          <option
                            value={alternative_mobile?.alternate_mobile}
                            key={alternative_mobile?.pam_id}
                          >
                            {alternative_mobile?.alternate_mobile}
                          </option>
                        )
                      )}
                  </Form.Select>
                ) : (
                  <Form.Control
                    className="input_field"
                    type="text"
                    placeholder="Enter your phone number"
                    isInvalid={!!errors.patient_mobile}
                    {...register("patient_mobile")}
                  />
                )}

                <Form.Text className="text-danger">
                  {errors.reason_id?.message as string}
                </Form.Text>

                <Form.Control.Feedback type="invalid">
                  {(errors.patient_mobile?.message as string) || ""}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Select Reason */}
              <Form.Group className="mb-3" controlId="reason_id">
                <Form.Label className="fs-14 fw-400 text-muted m-0">
                  Select Reason
                </Form.Label>
                <Form.Select
                  className="input_field"
                  isInvalid={!!errors.reason_id}
                  {...register("reason_id")}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleSelectChange(e.target.value, 'reason_id')}
                >
                  <option value="">Select Reason</option>
                  {reasonList &&
                    reasonList.map((reason: Reason) => {
                      return (
                        <option value={reason.id} key={reason.id}>
                          {reason.reason}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {(errors.reason_id?.message as string) || ""}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Message */}
              <Form.Group className="mb-3" controlId="message">
                <Form.Label className="fs-14 fw-400 text-muted m-0">
                  Message
                </Form.Label>
                <Form.Control
                  as="textarea"
                  className="input_field"
                  rows={4}
                  placeholder="Enter your message"
                  isInvalid={!!errors.message}
                  {...register("message")}
                />
                <Form.Control.Feedback type="invalid">
                  {(errors.message?.message as string) || ""}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Terms & Conditions */}
              {/* <Form.Group className="mb-3" controlId="terms">
                <Form.Check
                  type="checkbox"
                  className="fs-14 fw-400"
                  label="By clicking this box, you agree to our Terms & Conditions."
                  isInvalid={!!errors.terms}
                  {...register("terms")}
                />
                <Form.Control.Feedback type="invalid">
                  {(errors.terms?.message as string) || ""}
                </Form.Control.Feedback>
              </Form.Group> */}

              {/* Submit Button */}
              <Button variant="secondary" type="submit" disabled={!isValid}>
                Submit
              </Button>
            </Form>
          </Container>
        </Modal.Body>

        <Modal.Footer className="border-0 justify-content-start p-4">
          &nbsp;
        </Modal.Footer>
      </Modal>
    );
};

export default SpeakWithExpert;