import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiResponse } from '../../types';
import { ContractDetail, ContractListInput, ContractService, CustomerContractList } from '../../types/contract.type';
import API, { downloadApi } from '../../service/api.service';
import { RootState } from '../reducers';

export interface ContractListResponse extends ApiResponse {
  paginated_contracts: CustomerContractList[];
}

export const contractList = createAsyncThunk<ContractListResponse, { data: ContractListInput }, { rejectValue: any }>(
  'contract/list',
  async ({ data }, { rejectWithValue }) => {
    try {

      const response = await API({
        api: `details/get_contract_list?limit=${data.limit}&offset=${data.offset}`,
        access_token: data.access_token,
        type: 'GET'
      });

      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({ message: (response.original_text), flag: response.flag });
      }

      const result = response?.data ? { ...response.data } : { ...response };

      return result;
    } catch (err: any) {
      return rejectWithValue({ flag: err.flag, message: err.response?.data?.message || 'Failed to fetch contract list' });
    }
  }
);

interface ContractDetailsInput {
  id: number;
  navigate?: Function;
}


export interface ContractDetailResponse {
  is_error?: number,
  flag?: number,
  tracking_id?: string,
  request_id?: number,
  message: string,
  contractDetail: ContractDetail;
  contractServices: ContractService[];
  navigate?: Function;
}

export const contractDetails = createAsyncThunk<ContractDetailResponse, { data: ContractDetailsInput }, { state: RootState, rejectValue: any }>(
  'contract/detail',
  async ({ data }, { rejectWithValue }) => {
    try {

      const response = await API({
        api: `details/get_contract_details?contract_id=${data.id}`,
        type: 'GET'
      });

      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({ message: (response.original_text), flag: response.flag });
      }

      let result = response;
      result = { 
        contractDetail: (response?.contractInfo && response.contractInfo.length) ? { ...response.contractInfo[0] } : null, 
        navigate: data.navigate, 
        contractServices: (response.contractServices || []) 
      };

      return result;
    } catch (err: any) {
      return rejectWithValue({ flag: err.flag, message: err.response?.data?.message || 'Failed to fetch contract detail' });
    }
  }
);

interface TransactionDetailsInput {
  id: number;
}

export const downloadContract = createAsyncThunk<void, { data: TransactionDetailsInput }, { rejectValue: any }>(
  'download/contract',
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await downloadApi({
        api: `details/download_contract_pdf?contract_id=${data.id}`,
        type: 'GET',
      });

      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({ message: response.original_text, flag: response.flag });
      }

      const contentDisposition = response?.headers.get('Content-Disposition');
      const fileName = contentDisposition ? extractFileName(contentDisposition) : 'contract.pdf';

      const blobData = await response.blob();
      const blob = new Blob([blobData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // Create and click the anchor element
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = fileName;
      document.body.appendChild(anchor); // For Firefox compatibility
      anchor.click();

      // Clean up
      document.body.removeChild(anchor);
      URL.revokeObjectURL(url);
    } catch (err: any) {
      return rejectWithValue({
        flag: err.flag,
        message: err.response?.data?.message || 'Failed to fetch contract detail',
      });
    }
  }
);

// Helper function to extract file name
function extractFileName(contentDisposition: string): string {
  const match = contentDisposition.match(/filename="?(.+)"?/);
  return match && match[1] ? match[1] : 'contract.pdf';
}