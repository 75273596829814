import { ApiResponse } from "./common.type";

export interface PaymentMethodInput {
    patient_id: number;
    source_id?: number;
    secondary_source_id?: number;
}

export interface PaymentMethodsResponse extends ApiResponse {
    payment_cards: PaymentCard[];
    source_id?: number;
    secondary_source_id?: number;
}

export interface PaymentMethods {
    default_payment_methods: PaymentCard[];
    backup_payment_methods: PaymentCard[];
    payment_methods: PaymentCard[];
}

export interface PaymentCard {
    patient_first_name: string;
    patient_last_name: string;
    card_type: string | null;
    exp_month: number;
    exp_year: number;
    funding: string;
    bank_status: string | null;
    type: number;
    source_id: number;
    doctor_id: number;
    patient_id: number;
    token: string;
    payment_source_type: number;
    brand: string;
    last4: string;
    verified_date: string;
    is_deleted: number;
    fingerprint: string;
    last_error: string | null;
    default: number;
    payment_source: string;
    stripe_flat_fee: number;
    stripe_percentage_fee: number;
    maximum_limit: number;
    currency_id: number;
    mandate: string | null;
}

export const paymentMatrix = {
    source_id: 0,
    brand: '',
    exp_month: 0,
    exp_year: 0,
    fingerprint: '',
    token: 0,
    last4: 0,
    payment_source_type: 0
}

export interface PaymentSourceType {
    primary: typeof paymentMatrix,
    backup: typeof paymentMatrix,
    recurring: typeof paymentMatrix
}

export interface changePaymentMethodInput {
    contract_id: number;
    source_id?: number;
    secondary_source_id?: number;
}

export interface partialPaymentInput {
    contract_id?: number;
    source_id?: number;
    payment_source_type?: number;
    amount?: number;
}
export interface patient_details {
    patient_name: string,
    city: string,
    state: string,
    postal_code: string,
    line1: string
}

export interface recurringPaymentInput {
    pf_id?: number;
    source_id?: number;
    payment_source_type?: number;
}

// interface PaymentMethod {
//   id: string;
//   object: string;
//   allow_redisplay: string;
//   billing_details: {
//     address: {
//       city: string | null;
//       country: string | null;
//       line1: string | null;
//       line2: string | null;
//       postal_code: string | null;
//       state: string | null;
//     };
//     email: string | null;
//     name: string | null;
//     phone: string | null;
//   };
//   created: number;
//   customer: string | null;
//   livemode: boolean;
//   metadata: Record<string, any>;
//   type: string;
//   us_bank_account: {
//     account_holder_type: string;
//     account_type: string;
//     bank_name: string;
//     financial_connections_account: string | null;
//     fingerprint: string;
//     last4: string;
//     networks: {
//       preferred: string | null;
//       supported: string[];
//     };
//     routing_number: string;
//     status_details: Record<string, any>;
//   };
// }

type CardSourceType = {
  payment_source_type: number,
  payment_source: string,
  stripe_flat_fee: number,
  stripe_percentage_fee: number,
  maximum_limit: number,
  currency_id: number
}

export interface AddPaymentMethodResponse {
  source_id: string | number,
  fingerprint: string,
  last4: string,
  brand: number,
  payment_source_type: number,
  card_type: CardSourceType
};

export enum PayNowType {
  PAYOFF = 'Full payment',
  PARTIAL = 'Partial payment',
  RECURRING = 'Recurring payment'
}

export type PaymentMatrix = {
  payment_matrix_type: string, 
  payment_source_type: string
}

export interface SwitchPaymentMethods {
    default_payment_methods: PaymentCard;
    backup_payment_methods: PaymentCard;
}

export type PaymentTypeStatic = {
    pt_name: string;
    payment_type: number;
  }