import React from 'react'
import "./login-sidebar.scss";
const LoginSidebarComponent = () => {
  return (
   <>
    <div className="login-left-side h-100 d-flex flex-column justify-content-between -p-4 overflow-lg-auto">
            <div>
              <img
                src="../../assets/recuvery/Recuvery_Logo.svg"
                alt=""
                className="fm-logo"
              />
            </div>

            <img
              src="../../assets/recuvery/theme-bg.svg"
              alt=""
              className="shield-icon"
            />
            <div className='login-leftbar-footer'>
              <h1 className="fs-46 fw-400 text-white m-0 pb-3 fs-46-sm">
              Simplify and Monitor Your Finances Effortlessly
              </h1>
              <p className="fs-16 fw-400 m-0" style={{color:"#59B4DE"}}>
              Your finances, made easy!
              </p>
            </div>
          </div></>
  )
}

export default LoginSidebarComponent