import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitalState, StatusType } from '../../types/common.type';
import { ContractDetailResponse, contractDetails, contractList, ContractListResponse } from '../actions/contracts.action';
import { ContractDetail, ContractService, CustomerContractList } from '../../types/contract.type';
import { routes_constants } from '../../service/constants';
import { resetStore } from '../actions/global.action';

interface ContractsInitialState extends InitalState {
  contractList: CustomerContractList[];
  contractDetail: ContractDetail | null;
  contractServices: ContractService[];
  status: StatusType;
}

const initialState: ContractsInitialState = {
  contractList: [],
  contractServices: [],
  contractDetail: null,
  status: StatusType.Idle
};

const contractsReducer = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    swipeSourceIds: (state) => {
      if(state.contractDetail){
        const new_source_id = state.contractDetail.secondary_source_id;
        const new_secondary_source_id = state.contractDetail.source_id;
        state.contractDetail = {
          ...state.contractDetail,
          source_id: new_source_id,
          secondary_source_id: new_secondary_source_id
        }  
      }
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(resetStore, () => initialState)
    .addCase(contractList.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(contractList.fulfilled, (state, action: PayloadAction<ContractListResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.original_text
            state.flag = action.payload.flag
          }
          else {
            state.contractList = action.payload.paginated_contracts;
            state.status = StatusType.Succeeded;
          }
        }
      })
      .addCase(contractDetails.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch contract detail';
        state.flag = 401;
      })
      .addCase(contractDetails.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(contractDetails.fulfilled, (state, action: PayloadAction<ContractDetailResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.message;
            state.flag = action.payload.flag;
          }
          else {
            let patient_payoff_amount: string | number = action?.payload?.contractDetail?.patient_payoff_amount || 0;
            patient_payoff_amount = typeof patient_payoff_amount === 'string' ? parseFloat(patient_payoff_amount) : patient_payoff_amount;
            state.contractDetail = {...action.payload.contractDetail, patient_payoff_amount: patient_payoff_amount };
            state.contractServices = (action.payload.contractServices && action.payload.contractServices.length) ? action.payload.contractServices : [];
            state.status = StatusType.Succeeded;
            
            if (action.payload.navigate) {
              action.payload.navigate(`${routes_constants.dashboard}?contract_id=${action.payload.contractDetail.contract_id}`);
            }
          }
        }
      })
      .addCase(contractList.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch contract list';
        state.flag = 401;
      })      
  }
});

export const { swipeSourceIds } = contractsReducer.actions;
export default contractsReducer.reducer;
