import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../service/api.service';
import { AuthInput, AuthResponse } from '../../types';
export interface AuthRejectResponse {
  is_error?: number,
  flag?: number,
  tracking_id?: string,
  request_id?: number,
  message: string,
  navigate?:Function;
  contract_code?: string;
}

export const authenticate = createAsyncThunk<AuthResponse, { data: AuthInput, navigate: Function}, { rejectValue: AuthRejectResponse }>(
  'auth/authenticate',
  async ({data, navigate}, { rejectWithValue }) => {
    try {
    
      if(!data.email || !data.password){
        return rejectWithValue({message: 'Please fill login form properly!', navigate});
      }

      const body = {
        patient_email: data.email,
        patient_password: data.password
     };

     const response = await API({
        api: `auth/email_login`,
        body: body,
        type: 'POST'
      });     
      
      if (response.is_error && response.is_error === 1) {
        if(response.original_text === 'object' && response.original_text !== null){
          return rejectWithValue({ message: (response.original_text.message), contract_code: response.original_text.contract_code, navigate, flag: response.flag });
        }
        else{
          return rejectWithValue({ message: (response.original_text), navigate, flag: response.flag });
        }
      }

      let result = response?.data ? {...response.data } : {...response };
      result = result?.reset_password_token ? {...result, reset_current_password: data.password, navigate} : {...result, navigate};

      if(result.account_approved === 0){
        return rejectWithValue({message: "Your account is not approved!", navigate, flag: 403});
      }

      return result;
    } catch (err: any) {
      return rejectWithValue({navigate, flag: err.flag, message: err.response?.data?.message || 'Failed to fetch auth details'});
    }
  }
);
export interface LogoutResponse{
  success: boolean;
  navigate?:Function
}

export const logout = createAsyncThunk<LogoutResponse, { }, { rejectValue: AuthRejectResponse }>(
  'auth/patient_logout',
  // eslint-disable-next-line no-empty-pattern
  async ({ }, { rejectWithValue }) => {
    try {

      const response = await API({
        api: `auth/patient_logout`,
        type: 'GET',
      });

      if (response.is_error && response.is_error === 1) {
        return rejectWithValue({message: (response.original_text), flag: response.flag});
      }
      
      const result = { success: true };
      
      return result;
    } catch (err: any) {
      return rejectWithValue({
        // navigate,
        flag: err.flag ?? null,
        message: err.response?.data?.message || 'Failed to logout',
      });
    }
  }
);



// export const loginAfterResetPass = createAsyncThunk<CustomerFullProfile, LoginAfterReset>(
//   'auth/loginAfterReset',
//   async (payload, { rejectWithValue }) => {
//     try {

//       return payload;
//     } catch (error) {
//       return rejectWithValue(false);
//     }
//   }
// );