import React from 'react';
import './shimmerLoader.scss';

export const ShimmerLoader: React.FC = () => {

  return (
    <div className="overlay">
     <div
        className=" p-3 d-flex justify-content-center align-items-center rounded-circle"
      >
        <div className="four-dots"></div>
        {/* <img src="../../assets/images/recuveryloader.svg" alt="" className="h-100" /> */}
      </div>
    </div>
  );
};